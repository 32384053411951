import { includeMany } from "../utils.js";

/** Visa click2pay virtual payment method.
 * Render should be disabled
 * @class FPay
 * @description Implementation of FastPay for the payment wall
 * @exports FPay
 */

export default class CTP {
  /** SetUp for C2P virtual method
   *
   * This is actually a pay-with-card method that uses ctp card storage
   * (tecs-like) and authenticates with ctp instead of redsys
   *
   * */
  srcProfiles = {}

  constructor(userSettings, key, fpay) {
    this.userSettings = userSettings;
    this.key = key;
    this.fpay = fpay;
    this.idToken = null;
    this.srcProfiles = null;
    this.cards = null;
    this.email = userSettings.email || '';
    this.phone = '';
    this.maskedValidationChannel = ''
    this.environment = userSettings.type ? userSettings.type.split('_')[0] : "sandbox";
    if (this.email) {
      this.onUserEmailEnter(this.email)
    }
  }

  sendMessage(info) {
    // TODO: Add an eventlistener for messages on iframeTecs in setup?
    const data = JSON.stringify(info);
    console.log(`[pwall_debug] ${data}`)
    const iframeTecs = document.getElementById("tecsIframe").contentWindow;
    iframeTecs.postMessage(data, "*");
  }

  clean(){
    this.cards = null;
    this.phone = null;
    this.srcProfiles = null;
    this.maskedValidationChannel = null;
  }

  async onLogout() {
    this.email = null;
    this.clean();
    await this.vSrc.unbindAppInstance(this.idToken)
  }

  async switchId({email = null}) {
    await this.onLogout();
    await this.onUserEmailEnter({email});
  }

  async onOTPEnter({ otp = null }) {
    // OTP Received from tecs, login and setup proiles.
    const result = await this.vSrc.completeIdentityValidation(otp).catch(() => {
      return this.sendMessage({ 'action': 'tecs_show_otp_failure' });
    });
    await this.onIdToken(result.idToken)
  }

  async onIdToken(idToken) {
    console.log(`Calling onIdToken with ${idToken}`)
    this.idToken = idToken;

    this.sendMessage({ 'action': 'tecs_show_ctp_loading_cards', 'type': 'visa'})

    this.srcProfiles = await this.vSrc.getSrcProfile(idToken).then(
      (data) => { return data }
    ).catch(
      (err) => { console.log(err); }
    );

    this.cards = this.srcProfiles.profiles.map(
      profile => {
        return profile.maskedCards.map(
          card => {
            return {
              'panExpirationMonth': card.panExpirationMonth,
              'panExpirationYear': card.panExpirationYear,
              'panLastFour': card.panLastFour,
              'cardId': card.srcDigitalCardId,
              'cardArt': card.digitalCardData.artUri,
              'idToken': profile.idToken
            }
          })
      }).flat().sort(
        (card, cardb) => {
          Date.parse(card.orderDate) > Date.parse(cardb.orderDate)
        })

    this.sendMessage({ 'action': 'tecs_show_ctp_cards', 'cards': this.cards, 'key': this.key, 'type': 'visa'})
  }

  async onPaymentChecked({ paymentData = null }) {
    // - Saved cards: we'll have this.ctp.idCard setup.
    // - New cards: a store() should have been executed, so we have
    //   paymentData set. BUT method should be ctp, so that payment intercepts
    //   it, encrypts the payload and returns it on visa format so we can call
    //   checkout
    if (!paymentData.idCard && !paymentData.saveCard) {
      // New card, without saving it (so, not using visa's system)
      this.fpay.paymentData = { 'request_id': paymentData.requestId }
      await this.fpay.pay()
    }


    let ctpPayload = {
      "srciActionCode": "",
      "srciTransactionId": window.pwall.sessionId,
      "idToken": paymentData.idToken ? paymentData.idToken : this.idToken,
      "windowRef": "pwall-fastpay",
      "dpaTransactionOptions": this.userSettings.transactionOptions
    };

    if (this.srcProfiles) {
      ctpPayload.srcCorrelationId = this.srcProfiles.srcCorrelationId;
    }

    if (paymentData.idCard) {
      // We choose a ctp card
      ctpPayload.srcDigitalCardId = paymentData.idCard;
    } else {
      // We've got a tecs token
      // get will need to ask pwall to encrypt the data with ctp certificates,
      // extracted from TECS
      const response = await window.pwall.rpc.pwall.encryptCtp(
        {
          'request_id': paymentData.requestId,
          'key': this.key
        }
      )

      if (!response.jwt) {
        this.sendMessage({ 'action': 'tecs_show_checkout_error' })
        return;
      }

      ctpPayload.encryptedCard = response.jwt
    }

    if(!this.srcProfiles){
      ctpPayload.srciActionCode =  "NEW_USER";
      if(this.fpay.paymentWall.session && this.fpay.paymentWall.session.billing){
        let billing = this.fpay.paymentWall.session.billing
        ctpPayload.consumer = {
          emailAddress: this.email || billing.email,
          mobileNumber: {
              countryCode: "34",
              phoneNumber: this.phone || billing.phone
          },
          firstName: billing.name,
          lastName: billing.surname,
          consumerIdentity: {
            identityValue: this.email || billing.email,
            type: 'EMAIL_ADDRESS'
          }
        }
      }
    }

    window.ctpPayload = ctpPayload;

    // Store card data in secure redis
    await this.vSrc.checkout(ctpPayload).then(
      async (encrypted_card_data) => {
        if(encrypted_card_data.dcfActionCode == 'CANCEL'){
          return;
        }
        this.fpay.processingPayment();
        await window.pwall.rpc.pwall.decryptCtp({
          'key': this.key,
          'request_id': window.pwall.sessionId,
          'checkoutResponse': encrypted_card_data.checkoutResponse,
          'dpaId': this.userSettings.initParams.srciDpaId,
          'src_correlation_id': this.srcProfiles ? this.srcProfiles.srcCorrelationId : "",
        })

        this.fpay.paymentData = {
          'request_id': window.pwall.sessionId,
          'src_dpa_id': this.userSettings.initParams.srciDpaId,
          'ctp_type': "visa",
          'src_correlation_id': this.srcProfiles ? this.srcProfiles.srcCorrelationId : "",
        }
        if(paymentData.newUser){
          localStorage.setItem('ctpNewUser', 'true');
        }
        return await this.fpay.pay()
      }
    ).catch((err) => {
      console.log(err);
      window.pwall.placeholder.dispatchEvent(new Event("payment_wall_stop_processing_payment"))
      this.sendMessage({ 'action': 'tecs_show_checkout_error' })
    });
  }

  async onUserEmailEnter({ email = null }) {
    // When ctp login has been shown and user enters its email, launch this event.
    const consumer = await this.vSrc.identityLookup({
      identityValue: email, type: 'EMAIL'
    }).then((data) => data).catch((err) => err);

    if (consumer.consumerPresent) {
      // Login consumer, it actually exists
      const identity = await this.vSrc.initiateIdentityValidation().then(
        (data) => data
      ).catch(
        (err) => err
      );
      if (identity.maskedValidationChannel) {
        // Request otp to tecsiframe
        this.email = email;
        this.maskedValidationChannel = identity.maskedValidationChannel;
        this.sendMessage({ 'action': 'tecs_show_ctp_otp' })
      } else {
        // What to do if ctp doesn't respond with an OTP request?
      }
    } else {
      this.email = email;
      this.sendMessage({ 'action': 'tecs_show_ctp_register', 'key': this.key })
    }
  }

  async onResendOtp() {
    // When ctp login has been shown and user enters its email, launch this event.
    const consumer = await this.vSrc.identityLookup({
      identityValue: this.email, type: 'EMAIL'
    }).then((data) => data).catch((err) => err);

    if (consumer.consumerPresent) {
      // Login consumer, it actually exists
      await this.vSrc.initiateIdentityValidation().then(
        (data) => data
      ).catch(
        (err) => err
      );
    }
  }

  async checkIsRecognized() {
    const isRecognized = await this.vSrc.isRecognized().then((data) => data).catch((err) => err);

    if (isRecognized.recognized && isRecognized.idTokens) {
      await this.onIdToken(isRecognized.idTokens.values()[0])
    } else {
      this.sendMessage({ action: "tecs_show_ctp_login", type: 'visa', 'email': this.email });
    }
  }

  listCtpCards() {
    if(this.cards){
      this.sendMessage({ action: "tecs_list_ctp_cards", "cards": this.cards, "key": this.key, 'type': 'mastercard'});
    }
  }

  listMaskedValidationChannel() {
    let maskedEmail = ''
    let maskedPhone = ''
    if(this.maskedValidationChannel){
      let splittedValidationChannel = this.maskedValidationChannel.split(",");
      maskedEmail = splittedValidationChannel[0];
      maskedPhone = splittedValidationChannel[1];
    }
    this.sendMessage({ action: "tecs_list_ctp_masked_validation_channel", "maskedEmail": maskedEmail, "maskedPhone": maskedPhone});
  }

  async setUp() {
    this.clean();
    // Wrong kind of semaphore :-
    if (global.ctpInitializedFpay) {
      this.sendMessage({ 'action': 'tecs_ctp_setup_finished'});
      return
    }
    global.ctpInitializedFpay = true;

    const scripts = {
      sandbox: "https://sandbox-assets.secure.checkout.visa.com/checkout-widget/resources/js/src-i-adapter/visaSdk.js",
      production: "https://assets.secure.checkout.visa.com/checkout-widget/resources/js/src-i-adapter/visaSdk.js"
    }

    await includeMany([{
      'name': 'ctp',
      'attributes': [],
      'src': scripts[this.environment]
    }]).catch((err) => console.log(`Cant setup scripts: ${err}`))

    const callbacks = {
      'tecs_ctp_email_enter': this.onUserEmailEnter.bind(this),
      'tecs_ctp_enter_otp': this.onOTPEnter.bind(this),
      'tecs_ctp_payment_checked': this.onPaymentChecked.bind(this),
      'tecs_ctp_logout': this.onLogout.bind(this),
      'tecs_resend_otp': this.onResendOtp.bind(this),
      'tecs_check_is_recognized': this.checkIsRecognized.bind(this),
      'tecs_ctp_get_cards': this.listCtpCards.bind(this),
      'tecs_ctp_get_masked_validation_channel': this.listMaskedValidationChannel.bind(this),
      'tecs_ctp_switch_id': this.switchId.bind(this)
    }
    window.callbacks = callbacks;

    const messageListen = async (data) => {
      if (!data.data.startsWith('{')) {
        return;
      }
      console.log(`[pwall] Received message ${data.data}`);
      try {
        const parsed = JSON.parse(data.data);
        const action = parsed.action;
        if (callbacks[action]) {
          delete parsed['action'];
          console.log(await callbacks[action](parsed));
        }
      } catch (err) { console.log(err); }
    }

    try { window.removeEventListener('message', messageListen) } catch (err) { }

    window.addEventListener('message', messageListen)

    const vSrcAdapter = window.vAdapters.VisaSRCI;
    this.vSrc = new vSrcAdapter();
    
    if(window.pwall.session.order){
      this.userSettings.transactionOptions.merchantOrderId = window.pwall.session.order;
    };
    let amount = window.pwall.session.amount || window.pwall.amount;
    amount = parseInt(amount);
    let currency =  window.pwall.session.currency || window.pwall.currency;
    this.userSettings.transactionOptions.transactionAmount = {
      transactionAmount: (amount / 100).toFixed(2),
      transactionCurrencyCode: currency
    };

    if(this.fpay.paymentWall.session && this.fpay.paymentWall.session.billing){
      this.email = this.fpay.paymentWall.session.billing.email;
      this.phone = this.fpay.paymentWall.session.billing.phone;
    }

    var initParams = {
      ...this.userSettings.initParams,
      "srciTransactionId": window.pwall.sessionId ? window.pwall.sessionId : 'Sale_with_sipay',
      "dpaTransactionOptions": this.userSettings.transactionOptions
    };

    let result = [];

    try {
      result = await this.vSrc.init(initParams).catch((err) => { console.log(`CTP init error ${err}`) })
      await new Promise(r => setTimeout(r, 2000));
      this.sendMessage({ 'action': 'tecs_ctp_setup_finished', 'type': 'visa'})
    } catch (err) {
      console.log(err)
      return;
    }
  };
}
