import PaymentWallMethod from "./base.js";
import { VALID_CODES, INVALID_MESSAGES } from "./base.js";
import { transformDecimals } from "../utils.js";

/**
 * @typedef APaySettings
 * @property {string} [buttonsClass="apay-pay-button"] - All buttons of this
 *                                                       class will be used as
 *                                                       applepay
 * @property {ApayPaymentRequest} paymentRequest - Payment Request
 * @property {object} session_payload - Session Payload
 * @property {object} payload - Payload
 */

/** @typedef ApayTotal
  @property {string} label - Sale label
  @property {number} amount - Amount in integer
  */

/** @typedef ApayPaymentRequest
 * @property {string} countryCode - ISO3166 country
 * @property {array} [supportedNetworks=["amex", "discover", "masterCard", "visa"]]
 * @property {array} [merchantCapabilities=["supports3DS"]]
 */

/** Apple Pay payment wall method
 * @class APay
 * @description Implementation of FastPay for the payment wall
 * @exports APay
 *
 * @param {APaySettings} settings - Settings array
 *
 * */
export default class APay extends PaymentWallMethod {
  name = "apay";
  oneClickAvailable = true;
  /** SetUp for Apple Pay.
   * Set up payment settings
   */
  async setUp() {
    const amount = transformDecimals(
      this.paymentWall.amount,
      this.paymentWall.currency
    );

    const settings = {
      version: 2,
      buttonsClass: "apay-pay-button",
      paymentRequest: {
        total: {
          label: "Sale",
          amount: amount
        },
        currencyCode: "EUR",
        countryCode: "ES",
        supportedNetworks: ["amex", "discover", "masterCard", "visa"],
        merchantCapabilities: ["supports3DS"]
      },
      payload: {}
    };

    // Complete paymentRequest with defaults.
    settings.paymentRequest = {
      ...settings.paymentRequest,
      ...(this.settings.paymentRequest || {})
    };

    // Reassign it to settings.
    delete this.settings.paymentRequest;

    this.settings = { ...settings, ...this.settings };
    this.request_id = null;
    this.button = null;

    console.debug("[apay] Setting on buttons");
    for (let button of document.getElementsByClassName(settings.buttonsClass)) {
      console.debug("[apay] Setting on button ${button}");
      button.classList.toggle("visible");
      button.addEventListener("click", () => {
          this.beginAppleSession(event.target)
      });
    }
  }

  async isDisabled() {
    if (window.ApplePaySession && ApplePaySession.canMakePayments) {
      return [];
    } else {
      return ["apay"];
    }
  }

  /** 
   * Begins applepay session. It's called in setup and oneclickPayment method
   * 
   */
  beginAppleSession(button){
    this.button = button;
    let settings = this.settings;
    this.session = new window.ApplePaySession(
      settings.version,
      settings.paymentRequest
    );
    this.session = this.configureSession(this.session, settings, button);
    this.session.begin();
  }

  /**
   * Merchant Validation
   * Merchant MUST be validated, this is called upon apple pay validation sheet
   */
  configureSession(session, settings, button) {
    session.onvalidatemerchant = event => {
      window.pwall.rpc.session
        .apay({
          payload: {
            domain: window.location.host,
            url: event.validationURL,
            title: settings.paymentRequest.total.label
          }
        })
        .then(response => {
          this.request_id = response.request_id;
          return session.completeMerchantValidation(response);
        })
        .catch(() => {
          session.abort();
        });
    };

    /**
     * Payment Authorization
     * User completed the payment, auth token has been received.
     * Send it to Sipay and indicate status to apple pay session
     */
    session.onpaymentauthorized = this.doPay.bind(this)
    return session;
  }

  doPay(event) {
    event.payment.request_id = this.request_id;
    this.paymentData = { payment: event.payment };
    this.finish().then(() => {
      console.debug("[apay] Finished payment");
    });
  }

  getPaymentData() {
    this.settings.paymentRequest.total.amount = this.amount;
    this.settings.paymentRequest.total.currency_code = this.currency;
    return this.paymentData;
  }

  /** Execute a payment against jsonrpc
   *
   * Response will be evaluated, valid codes are defined in {VALID_CODES}
   * payment_ok or payment_ko will be launched
   *
   * @returns callback execution result
   * */
  async pay() {
    const result = await this.paymentWall.rpc.pwall.sale({
      method: this.name,
      returnDirect: true,
      ...this.paymentWall.extraData,
      ...this.getPaymentData()
    });
    if (result.code == "0") {
      this.session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
      return this.paymentWall.callbacks.payment_ok(result);
    } else {
      this.session.completePayment(window.ApplePaySession.STATUS_FAILURE);
      this.settings.callback_ko(response);
      return this.paymentWall.callbacks.payment_ko({ error: result.response });
    }
  }

  /**
   * Method to call beginApplepaySession from pwall app
   */
  oneClickPayment(){
    this.beginAppleSession();
  }
}
